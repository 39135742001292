import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DisplayModule } from '../display/display.module';
import { GoButtonComponent } from './go-button.component';
import { GoSignupButtonComponent } from './go-signup-btn.component';
import { LionsCollegeInterviewButtonComponent } from './lions-college-interview-button.component';
import { MakeTheBaseUseButtonComponent } from './make-the-best-use-button.component';
import { MaterialButtonComponent } from './material-button.component';
import { PlatformIntroductionComponent } from './platform-introduction.component';
import { RecommendedBrowserComponent } from './recommended-brower.component';
import { SchoolFreeConsultationButtonComponent } from './school-free-consultation-button.component';
import { ServiceUpgradeButtonComponent } from './service-upgrade-button.component';
import { SignupOrServiceUpgradeButtonComponent } from './signup-or-service-upgrade-button.component';
import { SolidmemoryAnimationButtonComponent } from './solidmemory-animation-button.component';
import { StepIndicatorComponent } from './step-indicator.component';
import { TroubleShootingButtonComponent } from './troubleshooting-button.componen';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule.forChild(),
    RouterModule,
    DisplayModule
  ],
  declarations: [
    LionsCollegeInterviewButtonComponent,
    SolidmemoryAnimationButtonComponent,
    GoButtonComponent,
    GoSignupButtonComponent,
    MakeTheBaseUseButtonComponent,
    MaterialButtonComponent,
    PlatformIntroductionComponent,
    RecommendedBrowserComponent,
    SchoolFreeConsultationButtonComponent,
    ServiceUpgradeButtonComponent,
    SignupOrServiceUpgradeButtonComponent,
    StepIndicatorComponent,
    TroubleShootingButtonComponent
  ],
  exports: [
    LionsCollegeInterviewButtonComponent,
    SolidmemoryAnimationButtonComponent,
    GoButtonComponent,
    MakeTheBaseUseButtonComponent,
    MaterialButtonComponent,
    PlatformIntroductionComponent,
    RecommendedBrowserComponent,
    SignupOrServiceUpgradeButtonComponent,
    SchoolFreeConsultationButtonComponent,
    ServiceUpgradeButtonComponent,
    StepIndicatorComponent,
    TroubleShootingButtonComponent
  ]
})
export class SharedUiModule {}
